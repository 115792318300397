<template>
  <div id="app">
      {{stateSync}}
      <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
  computed: {
    stateSync() {
      let stt = this.$store.state;
      localStorage.setItem("simpAdminState", JSON.stringify(stt));
      return "";
    },
  },
  created() {
    let savedstate = localStorage.getItem("simpAdminState");
    if (savedstate != null) {
      this.$store.replaceState(JSON.parse(savedstate));
    }
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
